import { AlertContext, Builder_, Confirmation_, Dropdown_, Main_Plain, Red_, Textarea_ } from 'monica-alexandria'
import React, { act, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import store from '../../../../redux/store'
import { Date_Dropdown } from './Date_Dropdown'
import { create_action, delete_action } from '../../../../services/api/Leads'
import { Date_Time_Picker } from '../../../partials/datetimepicker/Date_Time_Picker'
import { alertMessage } from '../../../../helpers/messagesAlerts'
import { checkPermissions } from '../../../../helpers/checkPermissions'


const LeadsActions = styled.div`

`

const LeadsEditNewAction = styled.div`
    background: ${(props) => props.theme.mid};
    border-radius: var(--smallRadius);
    padding: var(--largePads);
    margin-bottom: 2rem;
    background: ${p=>p.theme.lows};

  h6{
    padding: .5rem 1rem;
    border-radius: 1rem;
    background: ${p => p.theme.low};
    display: inline-block;
    margin-bottom: 1rem;
  }
`

const LeadsEditBtn = styled.div`
    display: flex;
    justify-content: right;
    
    .Red{
        margin-bottom: 1rem;
    }

    .MainPlain{
        margin-top: 2rem;
    }
`


const LeadsEditTextarea = styled.div`
	position: relative;
    padding-top: 1rem;

	
	button{
		position: absolute;
		bottom: 1rem;
		right: 1rem;
	}
`

const ActionItem = styled.div`
  margin-top: 1rem;
  border-radius: var(--smallRadius);
  padding: 1rem;
  background: ${p => p.theme.background};

  h6{
    padding: .5rem 1rem;
    border-radius: 1rem;
    background: ${p => p.theme.low};
    display: inline-block;
    margin-bottom: 1rem;
  }

  h5{
    margin-left: .5rem;
  }

  p{
    margin-top: 1rem;
    text-align: right;
  }
`


const LeadsEditDateTime = styled.div`
    padding-top: 1rem;
`
const LeadsEditAddAction = styled.div`
    border: 1px dashed var(--greyDark);
    background: ${p=>p.theme.mid};
    padding: var(--largePads);
    border-radius: var(--smallRadius);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`
 
const LeadsEditCreatedDate = styled.div`
    padding-top: 2rem;
    p{
        text-align: right;
    }
`

const LeadsEditNewActionTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Leads_Actions = (props) => {
    const {t} = useTranslation();
    const loggedUser = store.getState()?.user?.user;
	const [addNote, setAddNote] = useState(false)
    const {alerts,setAlerts} = useContext(AlertContext);

    const hasPermission = checkPermissions('Baron')
    const [dltAction, setDltAction] = useState(false)

    const actionOptions = [
        "Note",
        "Answered",
        "Not Answered",
        "Meeting Set",
        "Customer Lost",
        "Customer Won",
        "New Call",
        "Upsell"
    ];

    let filteredActions = [];
    let sortedActions = [];
        if (props.actionsDB) sortedActions = [...props.actionsDB]?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        else sortedActions=[];
        const hasCustomerWon = sortedActions?.some(action => action.action === "Customer Won");
        const lastCustomerLost = sortedActions?.reverse().find(action => action.action === "Customer Lost");
        
        filteredActions = (props.actionsDB && hasCustomerWon && (!lastCustomerLost || new Date(lastCustomerLost?.createdAt) < new Date(sortedActions?.find(action => action.action === "Customer Won")?.createdAt))) 
            ? ["Note", "Upsell"] 
            : actionOptions;
    
    
    const handleAddAction = () => {
 
        props.setActions([...props.actions, {
            id: Date.now(), // Unique ID for each action
            leadId: props.leadId || '',
            action: 'Note',
            schedule:false,
            comment: '',
            created: Date.now(),
            seller: loggedUser?.userId,
            addNote: false
        }]);
    };
    console.log('loggedUser', loggedUser);
    


        // Function to delete an action
        const handleDeleteAction = (state, index, action) => {
            console.log('actionAAAAAAAAAAAa', action);
            
            if(state ==='saved') {
                console.log('INDEXXXX', index);
                props.setActionsDB(props.actionsDB.filter((_, i) => i !== index));
                props.setActionsToDlt(prev => [...prev, action]);               
            }
            else props.setActions(props.actions.filter((_, i) => i !== index));

             setDltAction(false)
        };
        
        ;
        
        // Function to update an action in the list
        const updateAction = (id, updatedAction) => {
            props.setActions(props.actions.map(action =>
                action.id === id ? { ...action, ...updatedAction } : action
            ));
        };
    

        //comment textarea visibility
        const toggleAddNote = (index) => {
            props.setActions(props.actions.map((action, i) =>
                i === index ? { ...action, addNote: !action.addNote } : action
            ));
        };

        //if action.comment is defined on load show textarea
        useEffect(() => {
            props.setActions(props.actions.map(action =>
                !addNote && action.comment !== false ? { ...action, addNote: true } : { ...action, addNote: false }
            ));
        }, []);

    console.log('Actions DB', props.actionsDB);
    
  return (
    <LeadsActions>
        <Builder_ title={t('Actions')}>
        {dltAction !== false? 
            <Confirmation_
                message={`Are you sure you want to delete this action?`}
                no={t("No")}
                onNo={() => setDltAction(false)}
                onYes={() => {
                    handleDeleteAction(dltAction?.state, dltAction?.index, dltAction?.action)
				}}
                yes={t("Yes")}
            />
             : null}
        {props.actionsDB?.map((action, index) => (
                <LeadsEditNewAction key={action.id}>
                    {hasPermission ? 
                        <LeadsEditBtn>
                            <Red_ noPadding text='Delete' iconLeft='Delete' onClick={() => setDltAction({...dltAction,state:'saved', index: index,action: action?._id})}/>
                        </LeadsEditBtn>
                    : null}
                    <LeadsEditNewActionTop>

                    <h6>{action?.seller?.name ? action?.seller?.name : loggedUser?.firstname}</h6>
                    <p>{action?.createdAt ? new Date(action?.createdAt).toLocaleString("el-GR") :  new Date(action?.created).toLocaleString("el-GR")}</p>
                    </LeadsEditNewActionTop>

                    {action.action !=='Note' ? 
                        <ActionItem>
                            <h5>{action.action}</h5>
                        </ActionItem> 
                    : null}
                    {(action.action === 'New Call' || action.action === 'Meeting Set') ? (
                        <LeadsEditDateTime>
                             <ActionItem>
                                <h5>{new Date(action?.schedule).toLocaleString("el-GR")}</h5>
                            </ActionItem>
                        </LeadsEditDateTime>
                    ) : null}
                  
                 {action?.comment   ? 
                    <ActionItem>
                        <h5>{action.comment}</h5>
                    </ActionItem>
                    
                    : null}
                    {/* <LeadsEditCreatedDate>
                        <p>{action?.created ? new Date(action.created).toLocaleString("en-US") : "N/A"}</p>
                    </LeadsEditCreatedDate> */}
                </LeadsEditNewAction>
            ))}
            {props.actions?.map((action, index) => (
                <LeadsEditNewAction key={action.id}>
                        <LeadsEditBtn>
                           <Main_Plain noPadding text='Cancel' iconLeft='Xicon' onClick={() => handleDeleteAction('index', index, '')}/>
                            {/* <Main_Plain noPadding text='Cancel' iconLeft='XIcon' onClick={() => setDltAction({...dltAction,state:'index', index: index,action:''})}/> */}
                        </LeadsEditBtn>
                    <LeadsEditNewActionTop>

                    <h6>{action?.seller?.name ? action?.seller?.name : loggedUser?.firstname}</h6>
                    <p>{action?.createdAt ? new Date(action?.createdAt).toLocaleString("el-GR") :  new Date(action?.created).toLocaleString("el-GR")}</p>
                    </LeadsEditNewActionTop>

                    {/* <h3>New Action {index + 1}</h3> */}
                    <Dropdown_>
                    <select
                        value={action.action}
                        onChange={(e) => updateAction(action.id, { action: e.target.value })}
                    >
                        {filteredActions.map((option) => (
                            <option key={option} value={option}>
                                {t(option)}
                            </option>
                        ))}
                    </select>
                    </Dropdown_>

                    {(action.action === 'New Call' || action.action === 'Meeting Set') ? (
                        <LeadsEditDateTime>
                            <Date_Time_Picker
                                selected={action?.schedule}
                                updateAction={(scheduledDate)=>updateAction(action.id, { schedule: scheduledDate })}
                            />
                        </LeadsEditDateTime>
                    ) : null}
                    {action?.action !== 'Note' ?
                        <LeadsEditBtn>
                           <Main_Plain
                                noPadding
                                iconLeft={!action.addNote ? "Add" : "Xicon"}
                                text={!action.addNote ? "Add note" : "Remove note"}
                                onClick={() => toggleAddNote(index)}
                            />
                        </LeadsEditBtn>
                    :null}
                 {action?.action === 'Note' || action?.addNote  ? 
                  <LeadsEditTextarea>
                    <Builder_ >
                       
                        <Textarea_
                            value={action.comment}
                            onChange={(e) =>
                                updateAction(action.id, { comment: e.target.value })
                            }
                            placeholder="Add your note..."
                        />
                       
                    </Builder_>
                    </LeadsEditTextarea>
                    : null}
                    {/* <LeadsEditCreatedDate>
                        <p>{action?.created ? new Date(action.created).toLocaleString("en-US") : "N/A"}</p>
                    </LeadsEditCreatedDate> */}
                </LeadsEditNewAction>
            ))}
			 <LeadsEditAddAction onClick={handleAddAction}>
                <Main_Plain text={t('Action')} iconLeft='Add' />
            </LeadsEditAddAction>
        </Builder_>
    </LeadsActions>
  )
}
