import { edit_lead, add_tag, edit_notes, get_leads, reset_tag, get_sellers, merge_leads, search_lead, search_tags, create_action, delete_action } from "../../../services/api/Leads";
import { assign_seller } from "../../../services/api/History";
import { alertMessage } from "../../../helpers/messagesAlerts";

const handleSelections = (lead, selectionA, selectionB, setSelectionA, setSelectionB) => {
    
    if (selectionA?._id == lead?._id) {
        setSelectionA(false);
        setSelectionB(false);
    }
    else if (selectionB?._id == lead?._id) setSelectionB(false);
    else if (!selectionA) setSelectionA(lead);
    else if (!selectionB) setSelectionB(lead);
    else if ((selectionA && selectionB)) {
        setSelectionA(lead);
        setSelectionB(false);
    }
}

const handleCloseSlider = (setSelectedLead, setSlider) => {
    setSelectedLead(null);
    setSlider('');
};

const handleFilters = (category, value, setFilters)  => {

    console.log("filters!!", category, value);
    
    setFilters(prevFilters => {
        const categoryFilters = prevFilters[category];
        const updatedCategory = categoryFilters.includes(value)
          ? categoryFilters.filter(item => item !== value)
          : [...categoryFilters, value];
  
        return {
          ...prevFilters,
          [category]: updatedCategory
        };
      });
  };

const getSellers = (setSellers) =>{
    get_sellers()
        .then(data => {
            setSellers(data)
        })
        .catch(err => {
            console.log(err)
        })
}

const getLeads = (page, pageSize, leads, setLeads, setTotalLeads, setPageNo, setSources, slider, search, filters, dateFilters, setActions) => {


    let filtersActive = filters?.assign?.length || filters?.custom?.length || filters?.auto?.length || filters?.source?.length;

    let get_lead_promise = () => get_leads(1, page*pageSize);
    let search_lead_promise = () => search_lead(search);
    let filters_lead_promise = () => search_tags(filters, dateFilters, setLeads);
    
    let get_or_search;

    if (search) get_or_search = search_lead_promise;
    else if (filtersActive) get_or_search = filters_lead_promise;
    else get_or_search = get_lead_promise;


    if (slider == 'edit') {
        get_or_search()
        .then(data => {

            if (search || filtersActive) {
                setLeads(data)
            }
            else {
                setLeads(data?.leads);
            }

            setTotalLeads(data?.count)

            setPageNo(page+1)
        })
        .catch(err => {
            console.log(err)
        })
    }
    else {
        get_leads(page, pageSize)
        .then(data => {
            if (page == 1) {
                setLeads(data?.leads);
            }
            else {
                setLeads([...leads, ...data?.leads]);
            }
            
            setTotalLeads(data?.count)

            // const findSources = data?.leads?.reduce((acc, lead) => {
            //     if (lead.source && !acc.includes(lead.source)) {
            //         acc.push(lead.source);
            //     }
            //     return acc;
            // }, []);

            // setSources(findSources);

            setPageNo(page+1)
        })
        .catch(err => {
            console.log(err)
        })
    }
}

const handleSave = async (selectedLead, setSelectedLead, alerts, setAlerts, setSlider, user, pageNo, t, slider, pageSize, setPageNo, leads, setLeads, setTotalLeads, setSources, setLoading, userChanged, search, filters, dateFilters, actions, setActions, actionsToDlt) => {

    setLoading(true);

    if (selectedLead?.mergeLead && selectedLead?.mergeLead !== '') {

        let res;

        res = await merge_leads(selectedLead).catch(err => {console.log(err)})

        if (res?.data?.code !== 'T2017') {
            setLoading(false);
            alertMessage(alerts, setAlerts, res?.data?.msg, 'Error');
            return;
        }
    }

    if (selectedLead?.edit) {
        const cleanedLead = {
            ...selectedLead,
            firstname: selectedLead.firstname.trim(),
            lastname: selectedLead.lastname.trim(),
            
        };
        
        await edit_lead(cleanedLead).catch(err => {console.log(err)})

        setSelectedLead({ ...selectedLead, edit: false })
    }

    if (selectedLead?.customTag) {

        let res;

        res = await add_tag(selectedLead).catch(err => {console.log(err)})
        
        // if (res?.data?.code !== 'T2004') alertMessage(alerts, setAlerts, (t('There was an error adding a new note')), 'Error');
    }

    if (selectedLead?.customTag == '') {

        let res; 

        res = await reset_tag(selectedLead).catch(err => {console.log(err)})

        if (res?.data?.code !== 'T2004') alertMessage(alerts, setAlerts, (t('There was an error resetting tags')), 'Error');
    }
    
    if(userChanged) {

        await assign_seller(selectedLead, user).catch(err => {console.log(err)})
    }

    console.log('actionsToDlt', actionsToDlt);
    

    if (actionsToDlt?.length >0) {
         delete_action(actionsToDlt, selectedLead?._id)
            .then(res => { 
                // alertMessage(alerts, setAlerts, (t('Action deleted successfully')), 'Success');
                // props.setActionsDB(props.actionsDB.filter((_, i) => i !== index));
            })
        .catch(err => console.log(err))
    }

    if (actions) {
        try {
            for (const action of actions) {
                if (action?.action === 'Note' && action?.comment === '') {
                    console.log('Skipped action:', action);
                    continue; // Skip this iteration
                }
                await create_action(action);
            }
            console.log('All actions saved successfully');
        } catch (error) {
            console.error('Failed to save all actions:', error);
        }
        setActions([])
    }

    // let notes = await edit_notes(selectedLead).catch(err => {console.log(err)})
            
    // if (notes?.data?.code === 'T2015') alertMessage(alerts, setAlerts, (t('Lead edited successfully')), 'Success');
    // else alertMessage(alerts, setAlerts, (t('There was an error editing notes')), 'Error');

    setLoading(false);

    getLeads(pageNo-1, pageSize, leads, setLeads, setTotalLeads, setPageNo, setSources, slider, search, filters, dateFilters);
    handleCloseSlider(setSelectedLead, setSlider);
};

const getUserInfoOfAssignedSeller = (lead) => {

    if (!lead?.histories || !lead?.histories?.length) return '';

    let lastAssignedSellerIndex = lead.histories.length - 1;

    lead.histories[lastAssignedSellerIndex].seller.id = lead?.histories[lastAssignedSellerIndex]?.seller.sellerId;

    return lead?.histories[lastAssignedSellerIndex]?.seller;
}

const displayOpenTagAlongsideUnregistered = (lead) => {

    // let tags = lead?.tags?.filter(tag => tag.type !== 'source' && tag?.status && tag?.name !== 'Open');
    let tags = lead?.tags?.filter(tag => tag.type === 'auto' && tag?.status && tag?.name !== 'Open');
    // console.log('tags', tags);
    
    return tags;
}



const tagsList = (tags) => {

    return tags.map((tag, index) => {

        if (tag.type === 'source') {
            if (tag.name.includes('form')) {
                return { ...tag, action: 'Unregistered' };
            }
            else {
                return { ...tag, action: 'Registered' };
            }
        }

        if (tag.name === 'Unregistered' && !tag.status) {
            return { ...tag, action: 'Registered', createdAt: tag.updatedAt };
        }
        else if (tag.name === 'Unregistered' && tag.status) {
            return null;
        }

        return { ...tag, action: tag.name };

    }).filter(tag => tag);
};


const choice_no = () => {

}

const choice_yes = () => {
    
}


const validateProjectCodes = (codeValue, setMessages, setProjectCodeInput, t) => {
    let helpers = [];
    let successes = [];
    
    setProjectCodeInput(codeValue)

    // Regular expression to match a valid telephone number format
    const codeRegex = /^(DOWP|APP|DMT)-\d/;

    if (codeValue !== '') {
        if (!codeRegex.test(codeValue)) {
            helpers.push(t("Please enter a valid project code"));
        } else {
            successes.push(t("Project code is valid"));
        }
    }

    setMessages((prev) => ({
        ...prev,
        helpers: helpers,
        successes: successes,
    }));
};



export {
    handleSelections, 
    choice_no, 
    choice_yes, 
    handleSave, 
    handleCloseSlider,
    handleFilters,
    getLeads,
    getSellers,
    getUserInfoOfAssignedSeller,
    displayOpenTagAlongsideUnregistered,
    tagsList,
    validateProjectCodes
}