import { Input_, Builder_, Confirmation_, Dropdown_, Elastic_Search, Main_Plain, Red_, Textarea_, Dates_, IconSvg, FormGroup, FormList, Grey_, Focus_ } from 'monica-alexandria'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getUserInfoOfAssignedSeller, validateProjectCodes } from '../utils'
import { Notes_ } from './Notes_'
import store from '../../../../redux/store'
import { Date_Dropdown } from './Date_Dropdown'
import { Leads_Actions } from './Leads_Actions'
import { checkPermissions } from '../../../../helpers/checkPermissions'
import { search_lead_emails } from '../../../../services/api/Leads'
import i18n from '../../../../localization/i18next';

const LeadsEdit = styled.div`

`


const LeadsEditSplit = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`

const LeadSearch = styled(FormGroup)``;


const ElasticSuggestions = styled.ul`
  margin-top: 1rem;
  background: ${(props) => props.theme.background};
  box-shadow: ${(props) => props.theme.out};
  border-radius: var(--smallRadius);
  overflow: hidden;
  transition: all 0.5s ease;
  max-height: 20rem;
  overflow-y: scroll;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
    padding: var(--normalPads);
    transition: background 0.5s;
    /* border-bottom: .5px solid var(--greyLight); */

    p{
        padding-left: 1rem;
    }
    &:hover {
      background: ${(props) => props.theme.low};
    }
  }

  .ElasticSelected {
    background: ${(props) => props.theme.low};
  }
`;

const ElasticList = styled(FormList)`
  h5 {
    padding: var(--smallPads);
  }
`;


const clients = require('../../../../config/index').config
const griphookClient = process.env.REACT_APP_GRIPHOOK_CLIENT;


export const Leads_Edit = ({selectedLead, setSelectedLead, sellers,user,setUser, handleDeleteLead, leads, actions, setActions, actionsDB, setActionsDB, setActionsToDlt}) => {

    const {t} = useTranslation();
    const [leadToDelete, setLeadToDelete] = useState(false)

    const [messages, setMessages] = useState({successes:[], helpers:[], errors:[]});

    const [highlighted, highlight] = useState(0);

    // const [actions, setActions] =useState([])
    const [emailValue, setEmailValue] = useState('');
    const lang = i18n.language ? i18n.language : 'en';


	const [phoneInput, setPhoneInput] = useState('')
	const [mailInput, setMailInput] = useState('')
	const [projectCodeInput, setProjectCodeInput] = useState('')

    const [leadEmails, setLeadEmails] = useState([])
    
    const loggedUser = store.getState()?.user?.user;
    const [showSuggestions, setShowSuggestions] = useState(false)

    const [telMessages, setTelMessages] = useState({successes:[], helpers:[], errors:[]});
    

    const leadSearchRef = useRef(null);

 

        // Function to update an action in the list
        const updateAction = (id, updatedAction) => {
            console.log('id', id, updateAction);
            
            setActions(actions.map(action => action.id === id ? { ...action, ...updatedAction } : action));
        };


        const onAddToList = (input, field, setInputValue) => {
            if (telMessages?.helpers?.length){
                setTelMessages(prevMessages => ({
                    ...prevMessages,
                    errors: prevMessages?.helpers,
                    successes: [],
                    helpers: [],
            }));
            }
           else if (input) {
          
                setSelectedLead({ 
                    ...selectedLead, 
                    [field]: [...(selectedLead[field] || []), input], // Append new phone to the list
                    edit: true 
                });
                setTelMessages(prevMessages => ({
                    ...prevMessages,
                    errors: [],
                    successes: [],
                    helpers: [],
                }));
               
            }
      
            setInputValue(''); 
        };
    


        const onDeleteField = (index, listField, secondaryField, isPhone = false) => {
            const primaryListLength = selectedLead[listField]?.length || 0;
        
            if (index < primaryListLength) {
                // Remove from the primary list
                const updatedPrimaryList = selectedLead[listField].filter(
                    (_, i) => i !== index
                );
        
                setSelectedLead({
                    ...selectedLead,
                    [listField]: updatedPrimaryList,
                    edit: true 

                });
            } else {
                // Remove from the secondary list
                const adjustedIndex = index - primaryListLength; // Adjust index for secondary list
                const updatedSecondaryList = selectedLead[secondaryField].filter(
                    (_, i) => i !== adjustedIndex
                );
        
                setSelectedLead({
                    ...selectedLead,
                    [secondaryField]: updatedSecondaryList,
                    edit: true 

                });
            }
        };

        const getAllLeadEmails = (email) => {
            // setEmailValue(email)
            let data={

              searchQuery: email,
            }
            search_lead_emails(data)
            .then((res) => {
                console.log('getAllLeadEmails', res);
                // if(res?.data?.data?.code === 'P4000')  setAlerts([...alerts,{ message: { text: t('Couldnt find any plans for this interval'), status: 'Error' } }]);
                
                // else if (res.data?.code === 'S2003') 
                 setLeadEmails(res.data?.data)
                  setShowSuggestions(true)
              
              })
            .catch((err) => {});
          };
        
        
	

    const handleDelete = () => {
        handleDeleteLead(leadToDelete)
        setLeadToDelete(false)
    }

    const validateTel = (telNumber) => {
        let helpers = [];
        let successes = [];

        setPhoneInput(telNumber)
        // Regular expression to match a valid telephone number format
        const telRegex = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
        
        if (telNumber !== '') {
            if (!telRegex.test(telNumber)) {
                helpers.push("Please enter a valid phone number");
            } else {
                successes.push("Phone number is valid");
            }
        }

        setTelMessages(prevMessages => ({
                ...prevMessages,
                helpers: helpers,
                successes: successes,
        }));
    };


   const checkDuplicates = () => {
    if (selectedLead?.email && selectedLead?.emails?.includes(selectedLead?.email)) {
        // Remove `email` from `emails`
        const updatedEmails = selectedLead?.emails?.filter(
            (item) => item !== selectedLead.email
        );

        // Update the state
        setSelectedLead((prevState) => ({
            ...prevState,
            emails: updatedEmails,
        }));
    }

     // Check if `email` exists in `emails`
     if (selectedLead?.phone && selectedLead?.phones?.includes(selectedLead?.phone)) {
        // Remove `email` from `emails`
        const updatedPhones = selectedLead?.phones?.filter(
            (item) => item !== selectedLead.phone
        );

        // Update the state
        setSelectedLead((prevState) => ({
            ...prevState,
            phones: updatedPhones,
        }));
    }
    
   }
    useEffect(() => {
        checkDuplicates()
    }, [selectedLead]); 

   // Hide suggestions when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (leadSearchRef.current && !leadSearchRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    console.log('selected!!!!!!!!!!!', selectedLead);
    console.log('leadEmails!!!!!!!!!!!', leadEmails);

    
  return (
    <LeadsEdit>
        {leadToDelete !== false ? 
            <Confirmation_
                message={t("Are you sure you want to delete this lead?") }
                no={t("No")}
                onNo={ () => setLeadToDelete(false) }
                onYes= {()=> handleDelete()}
                yes={t("Yes")}
        /> : null }
        {/* <LeadActions>
            <Red_ noPadding text={t('Delete lead')} iconLeft="Delete" onClick={() => setLeadToDelete(selectedLead)} />
        </LeadActions> */}
        {selectedLead?.projectCodes?.length >0 && selectedLead?.userId  ? 
        <Builder_>
            <Focus_ iconLeft='Add' text={t('Subscription')} onClick={()=> window.location.assign(`${clients[griphookClient]}/${lang}/akihiko/griphook/subscribers/${selectedLead?.userId}`)}/>
        </Builder_>
        : null}
        <LeadsEditSplit>
            <Builder_ title={t("Enter lead's first name")}>
                <Input_
                    placeholder={t("First name")}
                    value={selectedLead?.firstname}
                    onChange={(e) => setSelectedLead({ ...selectedLead, firstname: e.target.value, edit: true })}
                />
            </Builder_>
            <Builder_ title={t("Enter lead's last name")}>
                <Input_
                    placeholder={t("Last name")}
                    value={selectedLead?.lastname}
                    onChange={(e) => setSelectedLead({ ...selectedLead, lastname: e.target.value, edit: true })}
                />
            </Builder_>
        </LeadsEditSplit>
       
        <Builder_ title={t("Enter lead's phone number")}>
            <Input_
				placeholder={t("Phone number")}
                list={[...selectedLead?.phoneList || [],...selectedLead?.phones || []]}
                defaultListItem={selectedLead?.phone?.length>0 ? [selectedLead?.phone] : null}
				icon_ListItem="Phone"
                onChange={(e)=>validateTel(e.target.value)}
				value={phoneInput} 
                onDelete={(index) =>
                    onDeleteField(index, "phoneList", "phones", true)
                }
				onAdd={()=>onAddToList(phoneInput.replace(/\s+/g, ''),'phones',setPhoneInput)}
				add={t('Add')}
                helpers={telMessages?.helpers}
                successes={telMessages?.successes}
                errors={telMessages?.errors}
			/>
        </Builder_>	
        <Builder_ title={t("Enter lead's emails")}>
			<Input_
				placeholder={t("Email")}
				list={[...selectedLead?.emailList || [],...selectedLead?.emails || []]}
                defaultListItem={selectedLead?.email?.length>0  ? [selectedLead?.email] : null}
				icon_ListItem="Email"
				onChange={(e)=> setMailInput(e.target.value)}
				value={mailInput} 
                onDelete={(index) =>
                    onDeleteField(index, "emailList", "emails")
                }
				onAdd={()=>onAddToList(mailInput.replace(/\s+/g, '').toLowerCase(),'emails',setMailInput)}
				add={t('Add')}
			/>
		</Builder_>	
        <LeadsEditSplit>	
            <Builder_ title={t("Enter lead's industry")}>
                <Input_
                    placeholder={t("Industry")}
                    value={selectedLead?.industry}
                    onChange={(e) => setSelectedLead({ ...selectedLead, industry: e.target.value, edit: true })}
                />
            </Builder_>	
            <Builder_ title={t("Enter lead's location")}>
                <Input_
                    placeholder={t("Location")}
                    value={selectedLead?.location}
                    onChange={(e) => setSelectedLead({ ...selectedLead, location: e.target.value, edit: true })}
                />
            </Builder_>	
        </LeadsEditSplit>
        <Builder_ title="Assign Seller">
            <Dropdown_>
                <select 
                    value={user?.id || 'Seller'} 
                    onChange={(e) => {
                        const selectedSeller = sellers.find(seller => seller.id === e.target.value);
                        if (selectedSeller) {
                            setUser(selectedSeller);
                        }
                        else {
                            setUser('Seller')
                        }
                    }}
                >
                    <option value={'Seller'}>{t('Seller')}</option>
                    {sellers?.map((seller) => (
                        <option
                            key={seller.id}
                            value={seller.id}
                        >
                            {seller.username}
                        </option>
                    ))}
                </select>
            </Dropdown_>
        </Builder_>

        <Builder_ title={t("Enter project codes ( DOWP|APP|DMT )")}>
            <Input_
				placeholder={t("Project codes")}
                list={[...selectedLead?.projectCodes || []]}
				icon_ListItem="Bullet"
				onChange={(e)=> validateProjectCodes(e.target.value, setMessages, setProjectCodeInput, t)}
				value={projectCodeInput}
                onDelete={(index) => onDeleteField(index, "projectCodes")}
				onAdd={()=>{
                    if (messages?.helpers?.length){
                        setProjectCodeInput('')
                        setMessages((prev) => ({...prev,errors:messages?.helpers, helpers: [], successes: []}));
                    } else {
                        setMessages((prev) => ({...prev,errors:[], helpers: [], successes: []}));
                        onAddToList(projectCodeInput,'projectCodes',setProjectCodeInput)
                    }
                    }}
				add={t('Add')}
                successes={messages?.successes}
                helpers={messages?.helpers}
                errors={messages?.errors}
			/>
        </Builder_>	

        <Builder_ title={t("Merge with another lead")}>
        
            <LeadSearch ref={leadSearchRef} className="ElasticSearch">
                    <Input_
                    id="elasticSearch"
                    placeholder={''}
                    autoComplete="off"
                    type="text"
                    value={emailValue}
                   

                    onChange={(e) => {
                        const inputValue = e.target.value.trim();
        
                        // Update the input value for fuzzy search
                        setEmailValue(inputValue);
                        setShowSuggestions(inputValue !== ''); // Show suggestions if input is not empty
                        getAllLeadEmails(inputValue);
                        if (inputValue === '') {
                            // Clear mergeLead when input is cleared
                            setSelectedLead((prev) => ({
                                ...prev,
                                mergeLead: '',
                            }));
                        }
                    }}
                    // onKeyPress={(e) => detectEnter(e)}
                    />
                {showSuggestions?
                    <ElasticSuggestions className="ElasticSuggestions">
                        {leadEmails?.map((lead, i) => (
                            <li
                                className={
                                    i === (highlighted % lead?.length) - 1
                                    ? "ElasticSelected"
                                    : ""
                                }
                                    key={i}
                                    onClick={() => {
                                        setSelectedLead({
                                            ...selectedLead,
                                            mergeLead: lead?.email || lead?.emails?.[0] || lead?.emailList?.[0], // Use the first email from emails array if email is empty
                                            mergeLeadEmails : [...lead?.emails || [], ...lead?.emailList|| []],
                                            edit: true,
                                        });
                                        setEmailValue(lead?.email || lead?.emails?.[0] ||  lead?.emailList?.[0]); // Update the input with the clicked email
                                        setShowSuggestions(false)
                                    }}
                            
                                >
                                    
							    <p>
                                    {[lead?.email || null, ...(lead?.emailList || []),...(lead?.emails || [])]
									.filter(email => email)
									.filter((email, index, self) => self.indexOf(email) === index) 
									.join(', ') 
								}
                                </p>

                            </li>
                        ))}
                    </ElasticSuggestions>  
                    :null}    
                </LeadSearch>
        </Builder_>
        <Leads_Actions
            leadId={selectedLead?._id}
            actions={actions} 
            actionsDB={actionsDB} 
            setActions={setActions}
            setActionsDB={setActionsDB}
            setActionsToDlt={setActionsToDlt}
        />
    </LeadsEdit>
  )
}
