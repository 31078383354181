import { Builder_, Dropdown_, Elastic_Search, Input_, Textarea_, Red_, Main_Plain } from 'monica-alexandria';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Date_Dropdown } from './Date_Dropdown';
import store from '../../../../redux/store';
import { Leads_Actions } from './Leads_Actions';
import { validateProjectCodes } from '../utils';

const LeadsAdd = styled.div``

const LeadsEditSplit = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`

export default function Leads_Add(props) {

    const {t} = useTranslation();
	const [addNote, setAddNote] = useState(false)

	const [phoneInput, setPhoneInput] = useState('')
	const [mailInput, setMailInput] = useState('')
	const [messages, setMessages] = useState({successes:[], helpers:[], errors:[]});
	const [projectCodeInput, setProjectCodeInput] = useState('')
	
	const [telMessages, setTelMessages] = useState({successes:[], helpers:[], errors:[]});

	const loggedUser = store.getState()?.user?.user;

	const validateTel = (telNumber) => {
        let helpers = [];
        let successes = [];

        setPhoneInput(telNumber)
        // Regular expression to match a valid telephone number format
        const telRegex = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
        
        if (telNumber !== '') {
            if (!telRegex.test(telNumber)) {
                helpers.push("Please enter a valid phone number");
            } else {
                successes.push("Phone number is valid");
            }
        }

        setTelMessages(prevMessages => ({
                ...prevMessages,
                helpers: helpers,
                successes: successes,
        }));
    };


    // const onAddToList = (input, field, setInputValue) => {
    //     if (input?.trim()) {
    //         const newPhone = input?.trim();
    //         props.setNewLead({ 
    //             ...props.newLead, 
    //             [field]: [...(props.newLead[field] || []), input?.trim()] // Append new phone to the list
    //         });
    //         setInputValue(''); 
    //     }
    // };


	const onAddToList = (input, field, setInputValue) => {
		if (telMessages?.helpers?.length){
			setTelMessages(prevMessages => ({
				...prevMessages,
				errors: prevMessages?.helpers,
				successes: [],
				helpers: [],
		}));
		}
	   else if (input) {
	  
			props.setNewLead({ 
				...props.newLead, 
				[field]: [...(props.newLead[field] || []), input?.trim()] // Append new phone to the list
			});
			setTelMessages(prevMessages => ({
				...prevMessages,
				errors: [],
				successes: [],
				helpers: [],
			}));
		   
		}
  
		setInputValue(''); 
	};


	
    const onDeleteField = (index, fieldName) => {
        const updatedValues = props.newLead[fieldName].filter((_, i) => i !== index);
        props.setNewLead({ 
            ...props.newLead, 
            [fieldName]: updatedValues // Set updated list for the specified field
        });
    };


    console.log('ACTIONS HELP', props.actions);
    console.log('ADD HELP', props.newLead);

	
  return (
    <LeadsAdd>
		<LeadsEditSplit>
			<Builder_ title={t("Enter lead's first name")}>
				<Input_
					placeholder={t("First name")}
					value={props.newLead?.firstname}
					onChange={(e)=>props.setNewLead({ ...props.newLead, firstname: e.target.value})}
				/>
			</Builder_>
			<Builder_ title={t("Enter lead's last name")}>
				<Input_
					placeholder={t("Last name")}
					value={props.newLead?.lastname}
					onChange={(e)=>props.setNewLead({ ...props.newLead, lastname: e.target.value})}
				/>
			</Builder_>
		</LeadsEditSplit>
		
		<Builder_ title={t("Enter lead's phone numbers")}>
			<Input_
				placeholder={t("Phone number")}
				list={props.newLead?.phones || []}
				icon_ListItem="Phone"
                onChange={(e)=>validateTel(e.target.value)}
				value={phoneInput} // Display phone numbers as comma-separated values
				onDelete={(index)=> onDeleteField(index, 'phones')}
				onAdd={()=>onAddToList(phoneInput.replace(/\s+/g, ''),'phones',setPhoneInput)}
				add={t('Add')}
				helpers={telMessages?.helpers}
                successes={telMessages?.successes}
                errors={telMessages?.errors}
			/>
		</Builder_>
        <Builder_ title={t("Enter lead's emails")}>
			<Input_
				placeholder={t("Email")}
				list={props.newLead?.emails || []}
				icon_ListItem="Email"
				onChange={(e)=> setMailInput(e.target.value)}
				value={mailInput} // Display phone numbers as comma-separated values
				onDelete={(index)=>onDeleteField(index, 'emails')}
				onAdd={()=>onAddToList(mailInput.replace(/\s+/g, '').toLowerCase(),'emails',setMailInput)}
				add={t('Add')}
			/>
     
		</Builder_>
            
		<LeadsEditSplit>	
            <Builder_ title={t("Enter lead's industry")}>
                <Input_
                    placeholder={t("Industry")}
                    value={props.newLead?.industry}
                    onChange={(e) => props.setNewLead({ ...props.newLead, industry: e.target.value})}
                />
            </Builder_>	
            <Builder_ title={t("Enter lead's location")}>
                <Input_
                    placeholder={t("Location")}
                    value={props.newLead?.location}
                    onChange={(e) => props.setNewLead({ ...props.newLead, location: e.target.value })}
                />
            </Builder_>	
        </LeadsEditSplit>

		<Builder_ title={t("Assign seller")}>
			<Dropdown_>
				<select 
					//value={props.user?.id || 'none'} 
					value={props.newLead?.assignee?.id || 'none'} 
					onChange={(e) => {
						const selectedSeller = props.fileAssignee?.find(seller => seller.id === e.target.value);
						
						if (selectedSeller) {
							props.setNewLead({ ...props.newLead, assignee: selectedSeller})
						}
					}}
				>
					<option value={'none'} disabled>{t('Seller')}</option>
					{props.fileAssignee?.map((seller) => (
						<option
							key={seller.id}
							value={seller.id}
						>
							{seller.username}
						</option>
					))}
				</select>
			</Dropdown_>			
		</Builder_>

		<Builder_ title={t("Enter project codes ( DOWP|APP|DMT )")}>
            <Input_
				placeholder={t("Project codes")}
				list={props.newLead?.projectCodes || []}
				icon_ListItem="Bullet"
				onChange={(e)=> validateProjectCodes(e.target.value, setMessages, setProjectCodeInput, t)}
				value={projectCodeInput}
                onDelete={(index) => onDeleteField(index, "projectCodes")}
				onAdd={()=>{
                    if (messages?.helpers?.length){
                        setProjectCodeInput('')
                        setMessages((prev) => ({...prev,errors:messages?.helpers, helpers: [], successes: []}));
                    } else {
                        setMessages((prev) => ({...prev,errors:[], helpers: [], successes: []}));
                        onAddToList(projectCodeInput,'projectCodes',setProjectCodeInput)
                    }
                    }}
				add={t('Add')}
                successes={messages?.successes}
                helpers={messages?.helpers}
                errors={messages?.errors}
			/>
        </Builder_>	

        <Leads_Actions 
            actions={props.actions} 
            setActions={props.setActions}
        />
		
    </LeadsAdd>
  )
}
